<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="名称" prop="title" >
        <a-input v-model="form.title" placeholder="请输入名称" />
      </a-form-model-item>
      <a-form-model-item label="门店logo" prop="logo" >
        <a-upload
          listType="picture-card"
          class="avatar-uploader"
          v-model="form.logo"
          :showUploadList="false"
          :beforeUpload='(info) => fileBeforeUpload("logo",info)'
          :customRequest='(info) => fileUploadRequest("logo",info)'
        >
          <img v-if="form.logo" :src="form.logo" alt="logo"
               style="height:104px;max-width:300px"/>
          <div v-else>
            <a-icon :type="loading ? 'loading' : 'plus'"/>
            <div class="ant-upload-text">上传</div>
          </div>
        </a-upload>

      </a-form-model-item>
      <a-form-model-item label="门店背景图" prop="bgImg" >
        <a-upload
          listType="picture-card"
          class="avatar-uploader"
          v-model="form.bgImg"
          :showUploadList="false"
          :beforeUpload='(info) => fileBeforeUpload("bgImg",info)'
          :customRequest='(info) => fileUploadRequest("bgImg",info)'
        >
          <img v-if="form.bgImg" :src="form.bgImg" alt="bgImg"
               style="height:104px;max-width:300px"/>
          <div v-else>
            <a-icon :type="loading ? 'loading' : 'plus'"/>
            <div class="ant-upload-text">上传</div>
          </div>
        </a-upload>

      </a-form-model-item>
      <a-form-model-item label="视频" prop="video" >
        <a-upload
          listType="picture-card"
          class="avatar-uploader"
          v-model="form.video"
          :showUploadList="false"
          :beforeUpload='(info) => fileBeforeUpload("video",info)'
          :customRequest='(info) => fileUploadRequest("video",info)'
        >
          <video v-if="form.video" :src="form.video" alt="video"  style="height:145px;max-width:325px"/>
          <div v-else>
            <a-icon :type="loading ? 'loading' : 'plus'"/>
            <div class="ant-upload-text">上传</div>
          </div>
        </a-upload>
      </a-form-model-item>

      <a-form-model-item label="详细地址" prop="address" >
        <MapModel ref="mappModel" :value="location" @change="changeCurrentAddress" />
<!--        <a-input v-model="form.address" placeholder="请输入详细地址" />-->
      </a-form-model-item>

      <a-form-model-item label="门店电话" prop="mobile" >
        <a-input v-model="form.mobile" placeholder="请输入门店电话" />
      </a-form-model-item>
      <a-form-model-item label="门店图片" prop="imgages" >
        <multi-img-upload   v-model="form.imgages" :value="form.imgages" :maxCount="6"></multi-img-upload>
      </a-form-model-item>
      <a-form-model-item label="门店描述" prop="desc" >
        <a-input v-model="form.desc" placeholder="请输入内容" type="textarea" allow-clear />
      </a-form-model-item>

      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getStore, addStore, updateStore } from '@/api/store/store'
import UploadFileToOSS from '@/utils/upload-file-to-oss'
import MultiImgUpload from '@/components/crud/form/MultiImgUpload.vue'
import MapModel from '@/components/MappModel.vue'


export default {
  name: 'CreateForm',
  props: {
  },
  components: {
    MapModel,
    MultiImgUpload
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      loading:false,
      location:"",
      address:"",
      // 表单参数
      form: {
        id: null,

        title: null,

        logo: null,

        bgImg: null,

        video: null,

        areap: null,

        areac: null,

        areax: null,

        address: null,

        lng: null,

        lat: null,

        mobile: null,

        imgages: null,

        desc: null,

        createTime: null,

        remark: null

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        title: [{ required: true, message: '名称不能为空', trigger: 'blur' }],
        logo: [{ required: true, message: '门店logo不能为空', trigger: 'blur' }],
        bgImg: [{ required: true, message: '门店背景图不能为空', trigger: 'blur' }],
        address: [
          {
            required: true,
            message: '门店地址不能为空',
            trigger: ['blur']
          }
        ],
        mobile: [
          {
            pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
            message: '请输入正确的手机号码',
            trigger: 'blur'
          }
        ]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    changeCurrentAddress(e) {
      console.log(e);
      this.address = e.address;
      this.location = e.location;
      this.form.address = e.address
      // this.$nextTick(() => {
      //   this.form.setFieldsValue({
      //     "longitude":e.location[0],
      //     "latitude":e.location[1],
      //     "address":e.address
      //   })})
    },
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        title: null,
        logo: null,
        bgImg: null,
        video: null,
        areap: null,
        areac: null,
        areax: null,
        address: null,
        lng: null,
        lat: null,
        mobile: null,
        imgages: null,
        desc: null,
        createTime: null,
        remark: null
      }
      // this.$nextTick(() => {
      //   if(this.$refs.form){
      //     this.$refs.form.resetFields()
      //   }
      // })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.location = []
      this.address = ""
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getStore({"id":id}).then(response => {
        this.form = response.data
        if(this.form.lng) {
          this.location = [this.form.lng,this.form.lat]
          this.address = this.form.address
        } else {
          this.location = []
        }
        this.open = true
        this.formTitle = '修改'
      })
    },
    /**
     * 文件上传前校验
     * @param {config.form.item} formItem 表单项
     * @param {*} fileInfo 文件详情, 见 a-upload 组件 customRequest 事件
     */
    fileBeforeUpload(field,fileInfo) {
      // 图片类型字段, 进行文件类型判断
      if (field != 'video' && !fileInfo.type.includes('image')) {
        this.$message.warning('请上传图片');
        return false;
      }
      return true;
    },
    /**
     * 文件上传请求自定义
     * @param {config.form.item} formItem 表单项
     * @param {*} fileInfo 文件详情, 见 a-upload 组件 customRequest 事件
     */
    fileUploadRequest(field,fileInfo) {

      this.loading = true;
      new UploadFileToOSS([fileInfo.file], {
        finally: (res) => {

          this.loading = false;
          // this.form.imgUrl = res[0].url;
          this.form[field] = res[0].url;
        },
      });
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          if (!this.location && !this.address) {
            this.$message.warn("门店地址不能为空");
            this.confirmLoading = false;
            return;
          }
          this.submitLoading = true
          this.form.lng = this.location[0];
          this.form.lat = this.location[1];
          this.form.address = this.address;
          if (this.form.id !== undefined && this.form.id !== null) {
            updateStore(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addStore(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
