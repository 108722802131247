<template>

  <div>
    <div class="selector-box">
      <div>
        <!-- 阿里图标需替换 -->

        <a-button :disabled="disabled" v-if="value.length == 0" type="primary" @click="showMapSelector">点击选择地址
        </a-button>
        <a-button :disabled="disabled" v-else @click="showMapSelector">
          经度：{{value[0]}}
          纬度：{{value[1]}}</a-button>
      </div>
<!--      <div>清空</div>-->
    </div>
    <a-modal title="点击选择地址" :visible="isShowMap" width="800px" @ok="save" @cancel="closeModal">
      <div class="map">
      <el-amap-search-box
        class="search-box"
        :search-option="searchOption"
        :on-search-result="onSearchResult"
      ></el-amap-search-box>
        <div style="height:10px;"></div>
      <el-amap
        :key="key"
        class="amap-box"
        vid="amap-vue"
        :zoom="amap.zoom"
        :center="amap.center"
        :plugin="amap.plugin"
        :events="amap.events"
      >
        <el-amap-marker
          v-for="(marker, index) in amap.markers"
          :key="'marker' + index"
          :position="marker.position"
        >
        </el-amap-marker>
        <el-amap-text
          v-for="(marker, index) in amap.markers"
          :key="'text' + index"
          :text="marker.text"
          :offset="marker.offset"
          :position="marker.position"
        ></el-amap-text>
      </el-amap>
      </div>
      <div class="container m-b-10">
        <el-amap :key="key" class="amap-box" :amap-manager="amapManager" :vid="'amap-vue' + key" :zoom="zoom" :plugin="plugin"
                 :center="center" :events="events">
          <!-- 标记 -->
          <el-amap-marker v-for="(marker, index) in markers" :position="marker" :key="index"></el-amap-marker>
        </el-amap>
      </div>

      <p>当前地址为：{{address}}</p>
      <p>经纬度：({{lng}},{{lat}})</p>
    </a-modal>

  </div>
</template>

<script src="//webapi.amap.com/ui/1.0/main.js?v=1.0.11"></script>
<script type="text/javascript">
window._AMapSecurityConfig = {
  securityJsCode: "335c58d0b77a2ed95b499d56cdf87b03",
}
</script>
<script type="text/javascript" src="https://webapi.amap.com/maps?v=1.4.15&key=c7f2583bbb31ac8580c76f4525f4503c"></script>
<script>
  /**
   * 高德地图选点组件
   *
   * @property {Array} value 地图坐标点
   * @property {Boolean} disabled 组件是否可用
   *
   * @event {Function()} change 点击确认或清除坐标点
   * @return {Object} location 经纬度 / address 位置
   *
   * @demo <by-amap-point-selector v-model="location" @change="changeLocation" />
   * */

  import {
    AMapManager,
    lazyAMapApiLoaderInstance
  } from 'vue-amap';

  import VueAMap from 'vue-amap';
  import Vue from 'vue';

  let amapManager = new AMapManager();
  let defaultPoint = [116.397451, 39.909187];

  export default {

    props: {
      // 初始化位置
      value: {
        type: Array,
        default: function() {
          return []
        }
      },
      // 是否禁用
      disabled: {
        type: Boolean,
        default: false
      }
    },
    created() {
      // 初始化高德地图
      Vue.use(VueAMap);
      window._AMapSecurityConfig = {
        securityJsCode: "335c58d0b77a2ed95b499d56cdf87b03",
      }
      VueAMap.initAMapApiLoader({
        key: 'c7f2583bbb31ac8580c76f4525f4503c',
        plugin: ['AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType',
          'AMap.PlaceSearch',
          'AMap.Geolocation', 'AMap.Geocoder'
        ],
        v: '1.4.5',
        uiVersion: '1.0.11'
      });
    },
    data() {
      let _this = this;

      // 高德地图事件处理
      const events = {
        // 地图初始化完毕
        init() {
          lazyAMapApiLoaderInstance.load().then(() => {
            _this.initSearch()
          })
        },
        // 地图点击
        click(e) {
          console.log('-----------------')
          console.log(e)
          _this.markers = []
          let {
            lng,
            lat,
          } = e.lnglat
          _this.lng = lng
          _this.lat = lat
          _this.center = [lng, lat]
          _this.markers.push([lng, lat]);
          _this.getAddress(lng, lat).then(res => {
            _this.address = res.formattedAddress;
          });
        }
      };

      // 高德地图插件
      const plugin = [{
        // 定位
        pName: 'Geolocation',
        events: {
          init(o) {
            console.log('Geolocation:', o);

            // o是高德地图定位插件实例
            o.getCurrentPosition((status, result) => {
              if (result && result.position) {
                // showMarker: true; //定位成功后在定位到的位置显示点标记，默认：true
                //   panToLocation: true; //定位成功后将定位到的位置作为地图中心点，默认：true
                //   zoomToAccuracy: true; //定位成功后调整地图视野范围使定位位置及精度范围视野内可见，默认：false
                // showButton: true; //显示定位按钮，默认：true

                // 设置经度
                _this.lng = result.position.lng;
                // 设置维度
                _this.lat = result.position.lat;
                // 设置坐标
                _this.center = [_this.lng, _this.lat];
                _this.defaultCenter = _this.center;
                _this.markers.push([_this.lng, _this.lat]);
                // 获取地址
                _this.getAddress(_this.lng, _this.lat).then(res => {
                  // _this.address = _this.searchKey = res.formattedAddress;
                  _this.address = _this.searchKey =  res.regeocode.formattedAddress;

                });
              }
            })
          },
          click(e) {
            console.log('点击了哦')
            console.log(e);
          }
        }
      },
        {
          // 工具栏
          pName: 'ToolBar',
          events: {
            init(instance) {
              console.log(instance);
            }
          }
        },
        {
          // 搜索
          pName: 'PlaceSearch',
          events: {
            init(instance) {
              console.log(instance)
            }
          }
        }
      ];
      const vm = this;
      return {
        key: +new Date(),
        isShowMap: false, // 展示选点弹窗
        address: null, // 地址
        searchKey: '', // 搜索
        markers: [defaultPoint], // 地图标点
        center: defaultPoint, // 当前坐标位置
        zoom: 17, // 最大缩放比例
        lng: 0, // 经度
        lat: 0, // 纬度
        amapManager, // 高德地图管理器
        events, // 高德地图事件处理
        plugin, // 高德地图插件
        addressData:{},
        dataForm: {
          lat: "39.909178",
          lon: "116.397756",
          orgAddr: "天安门"
        },
        // 地图搜索对象
        searchOption: {
          city: "",
          citylimit: true
        },
        mapInfo: {},
        amap: {
          zoom: 17,
          center: [116.397756, 39.909178],
          events: {
            click(e) {
              console.log(e, "e点击", self);
              let {lng, lat} = e.lnglat;
              self.lng = lng;
              self.lat = lat;
              // 这里通过高德 SDK 完成。
              var geocoder = new AMap.Geocoder({
                radius: 1000,
                extensions: "all"
              });
              geocoder.getAddress([lng, lat], function (status, result) {
                console.log('status, result');
                console.log(status, result);
                if (status === "complete" && result.info === "OK") {
                  if (result && result.regeocode) {
                    console.log("进来了");
                    vm.mapInfo = result;
                    // vm.dataForm.orgAddr = result.regeocode.formattedAddress;
                    vm.dataForm.orgAddr = result.regeocode.formattedAddress;
                    // res.
                    vm.dataForm.lat = lat ? lat.toString() : "";
                    vm.dataForm.lon = lng ? lng.toString() : "";
                    vm.markers = [];
                    vm.amap.markers = [];
                    const obj = {
                      position: [lng, lat],
                      // text: result.regeocode.formattedAddress,
                      text:  result.regeocode.formattedAddress,
                      offset: [0, 30]
                    };
                    vm.markers.push(obj);
                    vm.amap.markers.push(obj);
                  }
                } else {
                  console.log("报错了");
                  vm.$message.error(result);
                }
              });
            }
          },
        }
      };
    },
    methods: {
      // 展示地图选点组件
      showMapSelector() {
        this.isShowMap = true;
        this.$nextTick(() => {
          this.resetMap();
        })
      },

      // 初始化搜索
      initSearch() {
        let vm = this
        let map = this.amapManager.getMap()
        map.setZooms([2,26])
        AMapUI.loadUI(['misc/PoiPicker'], function(PoiPicker) {
          var poiPicker = new PoiPicker({
            input: 'search',
            placeSearchOptions: {
              map: map,
              pageSize: 10
            },
            suggestContainer: 'searchTip',
            searchResultsContainer: 'searchTip'
          })
          vm.poiPicker = poiPicker
          // 监听poi选中信息
          poiPicker.on('poiPicked', function(poiResult) {
            let source = poiResult.source
            let poi = poiResult.item
            if (source !== 'search') {
              poiPicker.searchByKeyword(poi.name)
            } else {
              poiPicker.clearSearchResults()
              vm.markers = []
              vm.amap.markers = []
              let lng = poi.location.lng
              let lat = poi.location.lat
              let address = poi.cityname + poi.adname + poi.name
              vm.amap.center = [lng, lat]
              vm.center = [lng, lat]
              vm.markers.push([lng, lat])
              vm.amap.markers.push([lng, lat])
              vm.lng = lng
              vm.amap.lng = lng
              vm.lat = lat
              vm.amap.lat = lat
              vm.address = address
              vm.searchKey = address
            }
          })
        })
      },
      // 地图搜索回调
      onSearchResult(pois) {
        const vm = this;
        vm.markers = [];
        vm.amap.markers = [];
        let latSum = 0;
        let lngSum = 0;
        if (pois.length > 0) {
          pois.forEach((poi, index) => {
            let { lng, lat } = poi;
            if (index === 0) {
              lngSum = lng;
              latSum = lat;
              const obj = {
                position: [poi.lng, poi.lat],
                text: poi.name,
                offset: [0, 30]
              };
              vm.markers.push(obj);
              vm.amap.markers.push(obj);
              vm.dataForm.orgAddr = poi.name;
              vm.dataForm.lat = poi.lat ? poi.lat.toString() : "";
              vm.dataForm.lon = poi.lng ? poi.lng.toString() : "";
            }
          });
          vm.center = [lngSum, latSum];
          vm.amap.center = [lngSum, latSum];
        }
      },
      // 返回经纬度地址
      getAddress(lng, lat) {
        let geocoder = new AMap.Geocoder({
          radius: 1000,
          extensions: 'all'
        })
        return new Promise((resolve) => {
          geocoder.getAddress([lng, lat], function(status, result) {
            if (status === 'complete' && result.info === 'OK') {
              if (result && result.regeocode) {
                console.log(result)
                resolve(result.regeocode)
              }
            }
          })
        })
      },
      // 重置地图组件
      resetMap() {
        let tempPos = this.value.length > 0 ? this.value : defaultPoint;
        this.center = tempPos;
        this.amap.center = tempPos;
        this.markers = [tempPos];
        this.amap.markers = [tempPos];
        this.lng = tempPos[0];
        this.lat = tempPos[1];
        this.searchKey = '';
        this.searchOption.city = '';

        // 延迟调用地址避免报错
        setTimeout(() => {
          this.getAddress(this.lng, this.lat).then(res => {
            console.log('++++++++++++')
            console.log(res)
            this.address = res.formattedAddress;
            // formattedAddress
          // .
          });
        }, 1000);
      },
      // 保存
      save() {
        console.log(this.address)
        console.log(this.lng, this.lat)
        this.change({
          address: this.address,
          location: [this.lng, this.lat]
        })
        this.$emit('ok', {"address": this.address,"lng":this.lng,"lat":this.lat})
        this.closeModal();
      },
      // 清除当前位置
      clearLocation() {
        this.change({
          address: '',
          location: []
        })
      },
      // 修改数据
      change(res) {
        this.$emit('input', res.location);
        this.$emit('change', res);
      },
      // 退出
      closeModal() {
        this.isShowMap = false;
      }
    }
  }
</script>

<style scoped lang="less">
  .container {
    width: 100%;
    height: 500px;
  }

  p {
    margin: 0;
  }

  .m-b-10 {
    margin-bottom: 10px;
  }
.search-box {
  width: 100%;
}
  .tip-box {
    width: 100%;
    max-height: 260px;
    position: absolute;
    top: 33px;
    overflow-y: auto;
    background-color: #fff;
    z-index: 999;
  }

  .selector-box {
    //width: 120px;
    padding: 0 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    //border: 1px solid #d9d9d9;
    background-color: #fff;

    >div {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
  }

  .close-icon {
    color: rgba(0, 0, 0, .25);
  }
</style>