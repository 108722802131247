import request from '@/utils/request'


// 查询门店列表
export function listStore(query) {
  return request({
    url: '/store/stores/list',
    method: 'get',
    params: query
  })
}

// 查询门店分页
export function pageStore(query) {
  return request({
    url: '/store/stores/page',
    method: 'get',
    params: query
  })
}

// 查询门店详细
export function getStore(data) {
  return request({
    url: '/store/stores/detail',
    method: 'get',
    params: data
  })
}

// 新增门店
export function addStore(data) {
  return request({
    url: '/store/stores/add',
    method: 'post',
    data: data
  })
}

// 修改门店
export function updateStore(data) {
  return request({
    url: '/store/stores/edit',
    method: 'post',
    data: data
  })
}

// 删除门店
export function delStore(data) {
  return request({
    url: '/store/stores/delete',
    method: 'post',
    data: data
  })
}
